import { useState } from "react";

const FAQ = () => {
    const [FAQs, setFAQs] = useState([
        {
            id: 0,
            question: "What we're looking for",
            answer: "We’re looking to our families and friends to raise a total of $15,000 by selling 10 shares equating to 1% of our company which should grant us approximately half of what we need at a minimum to reach our runway goal for the next 6 months. (Note this valuation is the same valuation that all investment to date has been raised at much earlier in our development process, we wanted to offer this floor level entry to our families and friends.)\n With your $15,000, we will be able to make headway on development of our standalone mobile app, which we need to get to the anticipated valuation and also retain users (as most people chat directly from their mobile phones).\n Come July, we aim to hit the “GO!” button, releasing Justine to accelerate promotions which we conservatively anticipate increasing our user count from 1000 to 100,000 in 2023.\nAlongside our advisors in the financial sector, we have done a tremendous amount of work doing our due diligence on the competitive landscape and have our investment pack including detailed financials ready and in place. Cafeteria is not like other social networks that focus on users and figure out the financial viability later, we have intentionally created several monetisation streams that are a natural part of the user journey, not bolt-ons that negatively affect user experiences.",
            active: true
        },
        {
            id: 1,
            question: "What we're offering",
            answer: "If you’re able to purchase equity based on our current valuation within Cafeteria, there are additional perks you will receive:\n<ol><li>$250+ = Cafeteria Premium for one year</li><li>$500 = Cafeteria Shirt</li><li>$1250 = Cafeteria Sweatshirt + Premium for life</li><li>$2500-5000 = A Premium or Enterprise Subscription for life + Special Bonus NFT (TBA)</li><li>Amounts under $250 are welcome but won’t qualify for equity, you will get a great gift though!</li></ol>\nConcurrent to this friends and family raise we are also conducting a bridge raise with the view to accelerate our feature completion and enable us to go to seed round and aggressive marketing sooner.",
            active: false
        },
        {
            id: 2,
            question: "What we'll do with the money",
            answer: "This is what we will be doing with the funds raised:\nWith the funds raised, we will ensure we can keep developing while we wait for our Bridge and Seed round later this year. (If you’re interested in either of these rounds, please <a href='mailto:contact@cafeteria.gg'>contact us</a>).\nThe next features we will be developing are those to ensure longevity in this space and a benchmark to begin mass marketing with the help of our Brand Ambassador and key advisor contacts.\nFirst step is to transition from using a 3rd party chat plugin API (Matrix) and write our own. This will ensure that we can scale properly without developing features to sit on top of a chat platform not intended to be so heavily modified. From there we will be able to develop our standalone mobile app for IOS and Android.",
            active: false
        },
        {
            id: 3,
            question: "Interested in more?",
            answer: "If you’re interested please <a href='mailto:contact@cafeteria.gg'>write to us</a> so we can include more information. Additionally if you think you know someone that might be interested in more than just 1%, we also have finder rewards if you successfully introduce us to a suitable investor.\nNote that equity has been earmarked for this F&F raise, the bridge round and main Seed round meaning that your equity stake will not be diluted by any of these funding rounds.",
            active: false
        },
        {
            id: 4,
            question: "Official Stuff",
            answer: "<ol><li>Cafeteria.gg Ltd is a United Kingdom based company</li><li>Non UK nationals can hold shares in UK companies </li><li>Cafeteria can’t give financial or tax advice so please get your own</li><li>Shareholdings will be recorded officially and registered at Companies House</li><li>An investment doesn’t guarantee our success so you may lose your money!</li><li><a href='/docs/TermSheet.pdf' target='_blank'>Official Term Sheet</a></li></ol>",
            active: false
        },
        {
            id: 5,
            question: "How to invest",
            answer: "Complete <a href='https://docs.google.com/forms/d/1G-VfMUYTmigGoNgmE_q_PCEKQBfxX8FjxxStD0bx3zc/edit'>this form</a> and select the amount and payment channel you want to invest through.",
            active: false
        },
        {
            id: 6,
            question: "How to get access to Cafeteria",
            answer: "<ol><li>Visit <a href='https://cafeteria.gg/' target='_blank'>https://cafeteria.gg/</a> (currently desktop site only)</li><li><strong>Click the key and padlock</strong> (top right corner)</li><li>Enter password: <strong>selectfew</strong></li><li>Sign in/up with a wallet or email</li><li>See you on Cafeteria!</li></ol>",
            active: false
        },                        
    ]);

    const handleFAQClick = (faqId) => {
        let newFAQs = [];
        FAQs.forEach(faq => {
            if (faq.id === faqId) {
                faq.active = !faq.active;
            } else {
                faq.active = false;
            }
            newFAQs.push(faq);
        });
        setFAQs(newFAQs);
    }

    return (
        FAQs.map((faq) => (
            <div className={`faq-element${faq.active ? ' active' : ''}`} key={faq.id} onClick={() => handleFAQClick(faq.id)}>
                <div className="faq-head">
                    <h3>
                        <span>
                            { faq.active ? <img src="/images/arrow-up.png" alt="arrow-up" /> : <img src="/images/arrow-down.png" alt="arrow-down" /> }
                        </span>
                        {faq.question}
                    </h3>
                </div>
                <div className="faq-answer">
                    {
                        faq.answer.split('\n').map((item, index) => {
                            return (<p dangerouslySetInnerHTML={{__html: item}}></p>)
                        })
                    }
                </div>
            </div>
        ))
    );
}

export default FAQ;
